import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Container = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 20px;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const VideoEmbed = ({ title, url }) => (
  <Container>
    <iframe title={title} src={url} frameBorder="0" allowFullScreen></iframe>
  </Container>
)

VideoEmbed.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}

export default VideoEmbed
