// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lineage-js": () => import("./../src/pages/lineage.js" /* webpackChunkName: "component---src-pages-lineage-js" */),
  "component---src-pages-work-js": () => import("./../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-work-work-template-js": () => import("./../src/work/workTemplate.js" /* webpackChunkName: "component---src-work-work-template-js" */)
}

